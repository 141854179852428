import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {graphql, useStaticQuery} from 'gatsby';

import Software from '../layouts/Software';

const SoftwarePageFr = () => {
  const data = useStaticQuery(graphql`
    query softwareDatasFr {
      image: file(relativePath: { eq: "software/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulGroupeDeFonctionnalitesLogiciel(filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            functionalities {
              id
              name
            }
            gif {
              file {
                url
              }
            }
          }
        }
      }
      allContentfulFonctionnaliteLogiciel(filter: {icon: {regex: ""}, node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            icon
          }
        }
      }
    }
  `)

  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/software' 
    slider={true} 
    headerImage={data.image.childImageSharp} 
    headerImageAlt="Gestion Affichage dynamique via logiciel Attractif"
    title="Le logiciel le plus simple à utiliser de l'industrie" 
    subTitle="L’affichage dynamique sur écran vous permet de communiquer de façon simple, efficace et créative avec votre clientèle et vos employés." 
    anchorLink="#software">
      <Seo 
        title="Application pour la gestion de votre affichage numérique"
        description="Notre application axée sur le contenu et accessible de partout vous permet de gérer de manière simple et intuitive l'affichage sur chacun de vos écrans, et ce, à partir d'une banque de gabarits ou avec vos modèles sur mesure."
       />
      <Software lang="fr-CA"  functionalitiesGroup={data.allContentfulGroupeDeFonctionnalitesLogiciel} functionalities={data.allContentfulFonctionnaliteLogiciel} />
    </Layout>
  )
}

export default SoftwarePageFr
